import { createStore } from "redux"

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONTACT_FORM_ACTION' :

      // action.payload.status

      // action.payload.location

      // action.payload.context


      console.log('action.payload', action.payload);

      return {
        ...state,
        modalForm: {
          open: action.payload.status,
          location: action.payload.location,
          context: action.payload.context,
        }
      }
    default:
      return state;
  }
}

const initialState = { contactFormOpen: false }

const configureStore = () => createStore(reducer, initialState)
export default configureStore